import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import { SEO } from '../components/SEO'

const About = () => {
  return (
    <>
      <SEO title='About Me | Entuit Enterprise Solutions Inc.'/>
      <Header />
        <div className="relative py-16 bg-white overflow-hidden">
          <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
            <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
              <svg
                className="absolute top-12 left-full transform translate-x-32"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
              >
                <defs>
                  <pattern
                    id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
              </svg>
              <svg
                className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
              >
                <defs>
                  <pattern
                    id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
              </svg>
              <svg
                className="absolute bottom-12 left-full transform translate-x-32"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
              >
                <defs>
                  <pattern
                    id="d3eb07ae-5182-43e6-857d-35c643af9034"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
              </svg>
            </div>
          </div>
          <div className="relative px-4 sm:px-6 lg:px-8">
            <div className="text-lg max-w-prose mx-auto">
              <h1>

                <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                  About me
                </span>
              </h1>
              <p className="mt-8 text-xl text-gray-500 leading-8">
                I have 24 years of experience in IT managing large enterprise environments as well as supporting small businesses develop and execute technology initiatives. 
                My focus for the last few years has been DevOps, AWS and supporting clients manage their application back ends. My current focus is developing my frontend and serverless skills.
              </p>
            </div>
            <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
              <p>
                I started as most do, providing desktop support, eventually progressing into a senior systems administrator role managing a large Sun Solaris environment.
                Over a few years I worked my way up to Director of IT for one of the largest content creation companies in North America. In that role I managed 3 divisional IT departments,
                a development team and a department responsible for managing digital assets.
              </p>
              <p>
                In 2003 I stepped away from that role and started out on my own. My goal was to provide the same level of service I received as a large corporate IT manager to small businesses.
                I have been fortunate to have worked with many large and small clients since. Below is a sample of the kind of work I have enjoyed.
              </p>
              <ul role="list">
                <li>Managed the flyer/catalogue IT infrastructure and merger of Sears Canada with St. Joseph Content.</li>
                <li>Designed, deployed and managed the infrastructure for Walmart.ca.</li>
                <li>Deployed and managed the Demand Response infrastructure for Ontario's electricity distribution sector.</li>
                <li>Designed, deployed and managed the infrastructure for a number of rewards programs in Canada. Including TD, CIBC and RBC</li>
                <li>Refactored and deployed a chat system on Amazon Web Services for Telus used to provide support to their Koodo clients.</li>
                <li>Recently built the stickerbeat.com ecommerce site in Gatsby using Shopify's storefront API.</li>
              </ul>
              <p>
                The jobs listed above span over a decade. As technology progressed I went from racking servers in data centres to deploying infrastructure on AWS using infrastructure as code.
                I don't get to spend as much time in noisy cold data centres today but I love the challenge of learning new ways to deploy technology. Recently I have been learning web3 technologies and have some experience building and deploying smart contracts on Ethereum. 
              </p>
              <p>
                Below is a synopsis of skills and technologies I consider myself proficient in.
              </p>
              <ul role="list">
                <li>Amazon Web Services (includes EC2, S3, Lambda, API Gateway, Beanstalk, SQS, SNS, CI/CD, CloudFormation, etc.)</li>
                <li>Serverless architecture and methodologies</li>
                <li>Containerized evironments (Docker, ECS)</li>
                <li>Relational and NoSQL databases (RDS, DynamoDB)</li>
                <li>Javascript, NodeJS, React, GatsbyJS</li>
                <li>REST and Graphql API development</li>
                <li>Systems architecture design</li>
                <li>DevOps methodologies</li>
                <li>Linux administration</li>
              </ul>
              <p>
                Lately I have been very interested in Web3 technologies and have an intermediate, and growing, knowledge of the following technologies:
              </p>
              <ul role="list">
              <li>Solidity</li>
                <li>Ethereum Blockchain (Go Ethereum)</li>
                <li>Hardhat development environment</li>
                <li>IPFS - decentralized storage</li>
              </ul>

            </div>
          </div>
        </div>
      <Footer />
    </>
  )
}

export default About
